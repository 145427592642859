//import { Link } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import UseClickOutside from "../components/UseClickOutside";
import HttpApi from '../api/HttpApi';
import { useInvestorData } from "../hooks/global"; 


const ToggleHeaderUser = () => {
  const investor = useInvestorData();
  const navigate = useNavigate();
  const { getRequest, token, logout } = HttpApi();

  const logOut = () => {
    if(token != undefined){
        logout();
    }
    getRequest('logout')
    .then((response)=>{
      //console.log(response.data.message.success);
      navigate('/');
    });
}


  const [toggleuser, setToggleuser] = useState(false);
  let domNode = UseClickOutside(() => {
    setToggleuser(false);
  });

  return (
    <div className="profile_log dropdown" ref={domNode}>
      <div className="user" onClick={() => setToggleuser(!toggleuser)}>
        <span className="thumb">
          <img 
          src={investor.investor_photo}
          className="rounded-circle"
          alt="photo" />
        </span>
        {/* <span>username</span> */}
        <span className="arrow">
          <i className="icofont-angle-down"></i>
        </span>
      </div>
      <div
        className={`dropdown-menu dropdown-menu-right ${
          toggleuser ? "show" : ""
        }`}
      >
        <div className="user-email">
          <div className="user">
            <span className="thumb">
              <img 
              src={investor.investor_photo}
              className="rounded-circle"
              alt="photo" />
            </span>
            <div className="user-info">
              {/* <h5>{investor.investor_name}</h5> */}
              <span>{investor.investor_no}</span>
            </div>
          </div>
        </div>

        <Link to="/profile">
          <a className="dropdown-item">
            <i className="bi bi-person"></i>Profile
          </a>
        </Link>
        {/* <Link to="/contracts">
          <a className="dropdown-item">
            <i className="bi bi-wallet"></i>Contracts
          </a>
        </Link>
        <Link to="/setting">
          <a className="dropdown-item">
            <i className="bi bi-gear"></i> Setting
          </a>
        </Link>         */}
        <Link onClick={logOut}>
          <a className="dropdown-item logout">
            <i className="bi bi-power"></i> Logout
          </a>
        </Link>
      </div>
    </div>
  );
};

export default ToggleHeaderUser;
