import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/SignIn";
import Withdraw from "./pages/Withdraw";
import WithdrawRequest from "./pages/WithdrawRequest";
import Account from "./pages/Account";
import Contract from "./pages/Contract";
import Profile from "./pages/Profile";
import Setting from "./pages/Setting";
import { useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import Notifications from "./pages/Notifications";
import ContractStatement from "./pages/ContractStatement";
import HttpApi from './api/HttpApi';

function App() {
  const { getToken } = HttpApi();
  const token = getToken();
  const isAuthenticated = !!token;


  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<SignIn />} />
        <Route exact path='/signin' element={<SignIn />} />
        
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/withdraws' element={<Withdraw />} />
        <Route exact path='/withdraw-request' element={<WithdrawRequest />} />
        <Route exact path='/account' element={<Account />} />
        <Route exact path='/contracts' element={<Contract />} />
        <Route exact path='/profile' element={<Profile />} />
        <Route exact path='/setting' element={<Setting />} />
        <Route exact path='/notifications' element={<Notifications />} />
        <Route exact path='/contract/statements/:contract_no' element={<ContractStatement />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
