import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import UseClickOutside from "../components/UseClickOutside";
import Helper from '../functions/Helper';
import HttpApi from '../api/HttpApi';

const NotificationToggle = () => {
  const { getRequest} = HttpApi();
  const [notifications, setNotification] = useState([]);

   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('notifications');
        setNotification(response.data.data.notifications);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
 }, []);

 const countNotifications = () => {
  return notifications.length;
};
const { timeAgo} = Helper();



  const [toggle, setToggle] = useState(false);
  let domNode = UseClickOutside(() => {
    setToggle(false);
  });
  return (
    <div className="notification dropdown" ref={domNode}>
      <div
        className="notify-bell"
        data-toggle="dropdown"
        onClick={() => setToggle(!toggle)}
      >
        <Link to="/notifications">
       <span className="position-relative">
          <i className="bi bi-bell"></i>
          {countNotifications() > 0 && (
            <span className="badge badge-danger badge-pill position-absolute top-0 start-100 translate-middle">
              {countNotifications()}
            </span>
          )}
        </span>
        </Link>
      </div>
      {/* <div
        className={`dropdown-menu dropdown-menu-right notification-list ${
          toggle ? "show" : ""
        }`}
      >
        <h4> <strong>{countNotifications()}</strong> Unread Notifications</h4>
        <div className="lists">
        {notifications.map((notification) => (
          
          <a href="#" className="">
            <div className="d-flex align-items-center">
              <span className="me-3 icon success">
                <i className="bi bi-check"></i>
              </span>
              <div>
                <p>{notification.title}</p>
                <span>{timeAgo(notification.created_at)}</span>
              </div>
            </div>
          </a>
        ))}
          <Link to="/notifications">
            <button className="btn btn-warning btn-block">
                      View More
                    </button>
            </Link>
        </div>
      </div> */}
    </div>
  );
};

export default NotificationToggle;
