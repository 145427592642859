import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import NotificationToggle from "./NotificationToggle";
import ToggleHeaderUser from "./ToggleHeaderUser";
import { useSettingData } from "../hooks/global"; 

const DashboardHeader = () => {
  const setting = useSettingData();

  return (
    <div className="header bg-default">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-12">
            <div className="header-content">
              <div className="header-left">
              <div className="brand-logo">
        <Link to="/">
          <a>
            <img src={setting.data?.settings?.logo} alt="" width="100"/>
          </a> 
        </Link>
      </div>
              </div>
 
              <div className="header-right">
                <div className="dark-light-toggle">
                  <span className="dark">
                    <i className="bi bi-moon"></i>
                  </span>
                  <span className="light">
                    <i className="bi bi-brightness-high"></i>
                  </span>
                </div>
                <NotificationToggle />
                <ToggleHeaderUser />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
