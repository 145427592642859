import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import HttpApi from '../api/HttpApi';
import { useSettingData } from "../hooks/global";


const SignIn = () => {
  const setting = useSettingData();

  const navigate = useNavigate();
  const {postRequest, setToken} = HttpApi();

  const [investor_no, setInvestorno] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    const signin_data = {
      investor_no: investor_no,
      password: password,
    };
    
    try {
      const response = await postRequest("signin", signin_data);

      if (response.data.status === "success") {
        setToken(
          response.data.data.investor,
          response.data.data.access_token
        );
        setErrors({});
        showNotification("success", response.data.message.success[0]);
        navigate("/dashboard");
      } else if (response.data.status === "error") {
        setErrors(response.data.message.error);
        //showNotification("error", "Sign-in failed. Please try again.");
      }
    } catch (error) {
      console.log(error);
      //showNotification("error", "Sign-in failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};


 
  return (
    <main className="main-wrapper">
  <section className="container custom-header flex-between">
    <a className="site-logo" href="">
    <img src={setting.data?.settings?.logo} alt="" width="150"/>
    </a>
     {/* <p className="account-alt">
          Have Inquirely? <a class="" href="">Contact Us</a>
      </p>  */}
  </section>
  <section className="page-content">
    <div className="container">
    <div class="row mb-4">
    <div class="col-md-12">
      {/* <h3 class="text-center">Join now - Access millions of opportunities are waiting for you</h3> */}
    </div>
  </div>

      <div className="row justify-content-md-center">
        <div className="col-md-5">
        <div className="auth-form card">
              <div className="card-body">
              <p class="mb-4">Enter signin credentials to access Dashboard.</p>
                
              <form onSubmit={handleSignIn}>
              <div className="form-group">
        <label htmlFor="investor_no">Enter Investor ID</label>
        <input
          type="text"
          name="investor_no"
          className={`form-control form-control-lg ${
            errors.investor_no ? "is-invalid" : ""
          }`}
          value={investor_no}
          onChange={(e) => setInvestorno(e.target.value)}
          onFocus={() => handleFocus('investor_no')}
        />
        {errors.investor_no && (
          <div className="invalid-feedback">{errors.investor_no[0]}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="password">Enter Password</label>
        <input
          type="password"
          name="password"
          className={`form-control form-control-lg ${
            errors.password ? "is-invalid" : ""
          }`}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={() => handleFocus('password')}
        />
        {errors.password && (
          <div className="invalid-feedback">{errors.password[0]}</div>
        )}
      </div>
                <div className="mt-4">
                <button
        type="submit"
        className="btn btn-info btn-lg btn-block"
        onClick={handleSignIn}
        disabled={loading}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          "Sign In"
        )}
      </button>
                </div>                      
              </form>

              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</main>

  );
};

export default SignIn;