import axios from 'axios';
import { useState, useEffect } from 'react';
import { Navigate, useNavigate, Link } from "react-router-dom";

//const baseURL = 'http://vuga.com/api/';
const baseURL = 'https://vugaweb.vugaboda.ug/api/';

export default function HttpApi() {
   
   const csrfTokenCookie = decodeURIComponent(document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN')));
   const csrfToken = csrfTokenCookie.split('=')[1];

   const getToken = () =>{
      const tokenString = localStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      return userToken;
  }

  const getUser = () =>{
      const userString = localStorage.getItem('user');
      const user_detail = JSON.parse(userString);
      return user_detail;
  }

  const [token,setToken] = useState(getToken());
  const [user,setUser] = useState(getUser());

  const saveToken = (user,token) =>{
   localStorage.setItem('token',JSON.stringify(token));
   localStorage.setItem('user',JSON.stringify(user));
      setToken(token);
      setUser(user);
      <Link to='/dashboard'></Link>
    //   return <Navigate to="" replace={true} />;
  }

    const logout = () => {
        try {
             localStorage.clear();
        } catch (error) {
            console.error('Error clearing localStorage:', error);
        }
    };

 const getRequest = (url) => {
    const headers = {
        Accept: 'application/json',
        "Content-type" : "application/json",
        "Authorization" : `Bearer ${token}`
       // 'X-CSRF-TOKEN': csrfToken,
    };
    const requestUrl = baseURL + url;

    return axios({
        method: 'GET',
        url: requestUrl,
        headers,
    });
}

const postRequest = (url, params) => {
    const headers = {
        Accept: 'application/json',
        "Content-type" : "application/json",
        "Authorization" : `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
    };
    const requestUrl = baseURL + url;

    return axios({
        method: 'POST',
        url: requestUrl,
        data: params,
        headers,
    });
}

const postMediaRequest = (url, params) => {
    const headers = {
        Accept: 'application/json',
        "Content-type" : "application/json",
        //"Content-Type": "application/json;charset=UTF-8",
        "Authorization" : `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
        "X-Requested-With" :"XMLHttpRequest",
        "Content-Type": "multipart/form-data"
    };
    const requestUrl = baseURL + url;

    return axios({
        method: 'POST',
        url: requestUrl,
        data: params,
        headers,
    });
}

// axios.interceptors.response.use(response => {
//     return response;
//  }, error => {
//    if (error.response.status === 401) {
//     //place your reentry code

//     //alert('error')
//    }
//    return error;
//  });

// // Implement Error Handling
// axios.interceptors.response.use(response => {
//     // Do something with successful response
//     return response;
//   }, error => {
//     if (error.response.status === 401) {
//       // User is not authenticated or token has expired
//     } else if (error.response.status >= 500) {
//       // Server error occurred
//     }
//     // Handle other errors
//     return Promise.reject(error);
//   });


//const token = localStorage.getItem('access_token');
axios.defaults.baseURL = baseURL;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common.Authorization = `Bearer ${token}`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
        logout();
        getRequest('logout')
    .then((response)=>{
      //console.log(response.data.message.success);
     // navigate('/');
    });
    }
    return Promise.reject(error);
  },
);


    return {
        setToken: saveToken,
        token,
        user,
        getToken,
        getUser,
        getRequest,
        postRequest,
        postMediaRequest,
        logout
    };
}