import { FC, useEffect } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import { AuthProvider } from '../contexts/AuthContext';

const DashboardLayout = ({ children }) => {
  useEffect(() => {
    document.querySelector("body")?.classList.add("dashboard");
  }, []);
  return (
    <AuthProvider>
      <div id="main-wrapper" className="show">
        <DashboardHeader />
        <DashboardSidebar />
        {children}
      </div>
    </AuthProvider>
  );
};

export default DashboardLayout;
