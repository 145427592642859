import { createContext, useContext, useState, useEffect } from 'react';
import HttpApi from '../api/HttpApi';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { getRequest, getUser, token } = HttpApi();
  const [investor, setInvestor] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getInvestorDetails = async () => {
      try {
        const response = await getRequest('investordata');
        setInvestor(response.data.data.investor);
      } catch (error) {
        console.error('Error fetching investor details:', error);
        
      }
    };
  
    if (token) {
      getInvestorDetails();
    } else {
      navigate('/');
    }
  }, [token, navigate]);
  

  return (
    <AuthContext.Provider value={{ token, investor }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};