import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HttpApi from '../api/HttpApi';

export function useSettingData() {
  const navigate = useNavigate();
   const { getRequest } = HttpApi();
   const [setting, setSetting] = useState([]);

   useEffect(() => {
      fetchSetting();
    }, []);
  
    const fetchSetting = () => {
      getRequest('settings').then((response) => {
         setSetting(response.data);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          navigate('/');
        }
        // Handle other errors
      });
      
    };
    
   return setting;
}

export function useInvestorData() {
   const { getRequest} = HttpApi();
   const [investor, setInvestor] = useState([]);

   useEffect(() => {
      fetchInvestor();
    }, []);
  
    const fetchInvestor = async () => {
      try {
        const response = await getRequest('investordata');
        setInvestor(response.data.data.investor);
      } catch (error) {
        console.log(error);
      }
    };
   return investor;
}