import React from "react";
import DashboardLayout from "../components/DashboardLayout";


const Account = () => {
   return (
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="page-title-content">
                  <h4> Account Page</h4>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Account;
