import React, { useState, useEffect } from "react";
import DashboardLayout from "../components/DashboardLayout";
import Helper from '../functions/Helper';
import HttpApi from '../api/HttpApi';
import Loader from '../loader/Loader';


const Withdraw = () => {
  const { showAmount, formatDate} = Helper();
  const {getRequest} = HttpApi();
  const [withdraws, setWithdraws] = useState([]);

  useEffect(() => {
    const fetchWithdraw = async () => {
      try {
        const response = await getRequest('withdraws');
        setWithdraws(response.data.data.withdraws);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWithdraw();
 }, []);

 const getStatusInfo = (status) => {
  switch (status) {
    case 0:
      return { text: "Pending", color: "warning" };
    case 1:
      return { text: "Approved", color: "success" };
    case 2:
      return { text: "Rejected", color: "danger" };
    default:
      return { text: "Unknown", color: "" };
  }
};

   return (
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
          <div className="row mb-3">
              <div className="col-md-8 mx-auto">
                <div className="page-title-content">
                  <h4>Withdraw Transactions</h4>
                </div>
              </div>
            </div>
            {withdraws ? (
            <div className="row">
            <div className="col-md-8 mx-auto">
            {withdraws.map((withdraw, index) => (
              <div className="card" key={withdraw.id}>
                <div className={`card-header bg-${getStatusInfo(withdraw.status).color}`}>
                  <h5 className="text-white">{formatDate(withdraw.created_at)}</h5>
                </div>
                <div className="card-body">
                  <h5 className="mb-2">Available Amount: <span className="float-right">{showAmount(withdraw.wallet_amount)}</span></h5>
                  {/* {withdraw.status === 0 ? (
                    <h5 className="mb-2">Request Amount: <span className={`float-right text-${getStatusInfo(withdraw.status).color}`}>{showAmount(withdraw.withdraw_amount)}</span></h5>
                  ) : (
                    <>
                    <h5 className="mb-2">Paid Amount: <span className={`float-right text-${getStatusInfo(withdraw.status).color}`}>{showAmount(withdraw.withdraw_amount)}</span></h5>
                    <h5 className="mb-2">Balance: <span className="float-right">{showAmount(withdraw.wallet_balance)}</span></h5>
                    </>
                  )} */}

                  {withdraw.status === 0 ? (
                    <h5 className="mb-2">Request Amount: <span className={`float-right text-${getStatusInfo(withdraw.status).color}`}>{showAmount(withdraw.withdraw_amount)}</span></h5>
                  ) : withdraw.status === 1 ? (
                    <>
                      <h5 className="mb-2">Paid Amount: <span className={`float-right text-${getStatusInfo(withdraw.status).color}`}>{showAmount(withdraw.withdraw_amount)}</span></h5>
                      <h5 className="mb-2">Balance: <span className="float-right">{showAmount(withdraw.wallet_balance)}</span></h5>
                    </>
                  ) : (
                    <>
                      <h5 className="mb-2">Request Amount: <span className={`float-right text-${getStatusInfo(withdraw.status).color}`}>{showAmount(withdraw.withdraw_amount)}</span></h5>
                    </>
                  )}
                  <h5>Status: <span className={`float-right text-${getStatusInfo(withdraw.status).color}`}>{getStatusInfo(withdraw.status).text}</span></h5>
                </div>
              </div>
))}

            
              </div>
            </div>
              ) : (
                <Loader />
              )}
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Withdraw;
