import React from "react";
import { useNavigate, Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import SettingsLayouts from "../components/SettingsLayouts";


const Setting = () => {
   return (
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
            <div className="row">
            <div className="col-xl-8 mx-auto">
              <div className="card">
                <div className="card-body">
                <div className="page-title">
                <h4>Settings</h4>
              </div>
                  
                </div>
               
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Setting;
