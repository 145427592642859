import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import HttpApi from '../api/HttpApi';
import Helper from '../functions/Helper';
import Loader from '../loader/Loader';

const Contract = () => {
   const { showAmount, showDate} = Helper();
   const { getRequest} = HttpApi();
   const [contracts, setData] = useState([]);

   useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRequest('contracts');
          setData(response.data.data.contracts);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
   }, []);

    
   return (
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
          <div className="row mb-3">
              <div className="col-md-8 mx-auto">
                <div className="page-title-content">
                  <h4> Contracts</h4>
                </div>
              </div>
            </div>

              <div className="row">
              <div className="col-md-8 mx-auto">
              {contracts ? (
                <div className="row">
                

                {contracts.map((contract) => (

<div key={contract.id} className="col-md-6">
     <div className="card">
       <div className="card-body">
          <h4 className="card-titlde text-center mb-4">{contract.contract_no}</h4>
          <div className="unpaid-content">
             <ul>
                <li>
                   <p className="mb-0">Investment Amount</p>
                   <h5 className="mb-0">{showAmount(contract.investment_amount)}</h5>
                </li>
                <li>
                   <p className="mb-0">Daily Earnings</p>
                   <h5 className="mb-0">{showAmount(contract.daily_amount)}</h5>
                </li>
                <li>
                   <p className="mb-0">Weekly Earnings</p>
                   <h5 className="mb-0">{showAmount(contract.weekly_amount)}</h5>
                </li>
                <li>
                   <p className="mb-0">Monthly Earnings</p>
                   <h5 className="mb-0">{showAmount(contract.monthly_amount)}</h5>
                </li>
                <li>
                   <p className="mb-0">Contract Amount</p>
                   <h5 className="mb-0">{showAmount(contract.contract_amount)}</h5>
                </li>
                <li>
                   <p className="mb-0">Current Earnings</p>
                   <h5 className="mb-0">{showAmount(contract.collected_amount)}</h5>
                </li>
                <li>
                   <p className="mb-0">Contract End Date</p>
                   <h5 className="mb-0">{showDate(contract.end_date)}</h5>
                </li>
             </ul>
             <div className="btn-group d-flex justify-content-center mt-2">
             <Link to={`/contract/statements/${contract.contract_no}`}>
                <button className="btn btn-warning btn-block">
                  View Statements
                </button>
                </Link>
              </div>
          </div>
       </div>
    </div>
 </div>
 
 ))}

 
                </div>
                ) : (
                  <Loader />
                )}
              </div>

               </div>
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Contract;