import { Link } from "react-router-dom";
import { FC, useEffect } from "react";
import { dashboardMenuActive } from "../utils/utils";
// import { dashboardMenuActive } from "../components/UseClickOutside";
const DashboardSidebar = () => {
  useEffect(() => {
    dashboardMenuActive();
  }, []);
  return (
    <div className="sidebar">
      <div className="menu" id="d_menu">
        <ul>
          <li>
            <Link to="/dashboard">
              <a className="dashboardIcon">
                <span>
                  <i className="bi bi-house"></i>
                  Home
                </span>
              </a>
            </Link>
          </li>
          <li>
            <Link to="/contracts">
              <a>
                <span>
                  <i className="bi bi-globe2"></i>
                  Contracts
                </span>
              </a>
            </Link>
          </li>
          <li>
            <Link to="/withdraws">
              <a>
                <span>
                  <i className="bi bi-wallet2"></i>
                  Withdraws
                </span>
              </a>
            </Link> 
          </li>
          <li>
            <Link to="/profile">
              <a id="settings__mainMenu">
                <span>
                  <i className="bi bi-gear"></i>
                  Profile
                </span>
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSidebar;
