import React,  { useState, useEffect }from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import HttpApi from '../api/HttpApi';
import { useDashboardData } from "../hooks/global";
import DashboardLayout from "../components/DashboardLayout";


const Profile = () => {
  const navigate = useNavigate();
  const { postMediaRequest, postRequest, getRequest, token, logout } = HttpApi();
  const [investor, setInvestor] = useState([]);

  useEffect(() => {
    const fetchInvestor = async () => {
      try {
        const response = await getRequest('dashboard');
        setInvestor(response.data.data.investor);
      } catch (error) {
        console.error(error);
      }
    };

    fetchInvestor();
 }, []);




  const logOut = () => {
    if(token != undefined){
        logout();
    }
    getRequest('logout')
    .then((response)=>{
      navigate('/');
    });
  }

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    const password_data = {
      password: password,
      confirm_password: confirm_password,
    };
    
    try {
      const response = await postRequest("updatepassword", password_data);

      if (response.data.status === "success") {
        setErrors({});
        showNotification("success", response.data.message.success[0]);
        logOut();
      } else if (response.data.status === "error") {
        setErrors(response.data.message.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    setPreviewUrl(investor.investor_photo);
  }, [investor.investor_photo]);

  
  const handlePhotoChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      // Handle case where no file is selected
      return;
    }

    const formData = new FormData();
    formData.append('photo', file);

    try {
      const response = await postMediaRequest("updatephoto", formData);
      if (response.data.status === "success") {
        setPreviewUrl(response.data.photo_url);
        //const imageUrl = URL.createObjectURL(file);
        showNotification("success", response.data.message.success[0]);
      } else if (response.data.status === "error") {
        showNotification("error", "Failed to upload the photo");
      }
    } catch (error) {
      console.log(error);
    }
  };
  

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

   return (
    <DashboardLayout>
    <div className="content-body">
      <div className="container-fluid">
        <div className="row">
        <div className="col-xl-8 mx-auto">
          <div className="card">
            <div className="card-body">
            <div className="page-title">
            <h4>Profile</h4>
          </div>
              <hr />

              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                      <div className="col-md-12">
                        <div className="card shadow-lg">
                          <div className="card-body">
                          <h5 className="mb-4">Profile Picture</h5>
  <div className="text-center">
      <div className="image-upload">
        <div className="thumb">
          <img
            alt="image"
            id="image_preview"
            src={previewUrl}
            className="rounded-circle"
            width={128}
          />
          
          <div className="upload-file">
            <input
              type="file"
              name="photo"
              id="photo"
              className="form-control file-upload"
              onChange={handlePhotoChange}
            />
            <label htmlFor="photo" className="btn bg-info">
              Change Profile
            </label>
            <span className="invalid-feedback" />
          </div>
        </div>
      </div>
    </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                      <div className="card shadow-lg">
                        <div className="card-body">
                        <h5 className="mb-4">Profile Information</h5>
  
  <div className=" col-md-12">
  <div className="user-info">
    <span>INVESTOR ID</span>
    <h4>{investor.investor_no}</h4>
  </div>
</div>
<div className="col-md-12">
  <div className="user-info">
    <span>NAMES</span>
    <h4>{investor.investor_name}</h4>
  </div>
</div>
<div className="col-md-12">
  <div className="user-info">
    <span>EMAIL</span>
    <h4>{investor.email && <>{investor.email}</>}</h4>
  </div>
</div>
<div className="col-md-12">
  <div className="user-info">
    <span>TELEPHONE</span>
    <h4>{investor.investor_telephone}</h4>
  </div>
</div>
<div className="col-md-12">
  <div className="user-info">
    <span>ADDRESSS</span>
    <h4>{investor.investor_address}</h4>
  </div>
</div>
<div className="col-md-12">
  <div className="user-info">
    <span>ADDRESSS</span>
    <h4>{investor.investor_source_income}</h4>
  </div>
</div>
                        </div>
                      </div>
                      </div>


                  </div>

                </div>
                
                <div className="col-md-6">
                  <div className="card shadow-lg">
                      <div className="card-body">
                      <h5 className="mb-3">Change Password</h5>
                <form onSubmit={handlePasswordChange}>
                     <div className="form-group">
                        <label htmlFor="password">Enter New Password</label>
                        <input 
                        type="password" 
                        name="password"
                        className={`form-control form-control-lg ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => handleFocus('password')}
                        />
                        {errors.password && (
                          <div className="invalid-feedback">{errors.password[0]}</div>
                        )}
                     </div>
                     <div className="form-group">
                        <label htmlFor="confirm_password">Confirm Password</label>
                        <input 
                        type="password" 
                        name="confirm_password"
                        className={`form-control form-control-lg ${
                          errors.confirm_password ? "is-invalid" : ""
                        }`}
                        value={confirm_password}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onFocus={() => handleFocus('confirm_password')}
                        />
                        {errors.confirm_password && (
                          <div className="invalid-feedback">{errors.confirm_password[0]}</div>
                        )}
                     </div>
                     <div className="mt-2">                     
                        <button
        type="submit"
        className="btn btn-info btn-lg btn-block"
        onClick={handlePasswordChange}
        disabled={loading}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          "Change Password"
        )}
      </button>
                     </div>                      
                  </form>
                      </div>
                  </div>
                </div>
              </div>






            </div>
           
          </div>
        </div>
      </div>
        
      </div>
    </div>
  </DashboardLayout>
    );
};

export default Profile;
