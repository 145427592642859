import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import HttpApi from '../api/HttpApi';
import Helper from '../functions/Helper';

const Notifications = () => {
  const { formatDate} = Helper();
  const { getRequest} = HttpApi();
  const [notifications, setNotification] = useState([]);

   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('notifications');
        setNotification(response.data.data.notifications);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
 }, []);
   
   return (
      <DashboardLayout>
        <div className="content-body">
        <div className="container-fluid">
        <div className="row">
            <div className="col-xl-8 mx-auto">
          <div className="card">
            <div className="card-body">
            <div className="page-title">
            <h4>Notifications</h4>
          </div>
              <hr/>

              <div className="notification-ui_dd-content">
              {notifications.map((notification) => (
               <div className="notification-list shadow-lg" key={notification.id}>
                  <div className="notification-list_content">
                     {/* <div className="d-flex align-items-center">
                     <span className="me-3 icon bg-success">
                        <i className="bi bi-check"></i>
                      </span>
                     </div> */}
                     <div className="notification-list_detail">
                     <p>
                        <b>{notification.title}</b>
                     </p>
                     <p className="text-muted"> {notification.detail}</p>
                     <p className="text-muted">
                        <small>{formatDate(notification.created_at)}</small>
                     </p>
                     </div>
                  </div>
               </div>
               ))}
               </div>

              </div>
              </div>
              </div>
              </div>






            <div className="row">
              <div className="col-xl-8 mx-auto">
              
              </div>
            </div>

            
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Notifications;