import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import HttpApi from '../api/HttpApi';
import Helper from '../functions/Helper';

const calculatePercentage = (value, total, decimalPlaces = 2) => {
  if (total === 0) {
    return 0;
  }

  const percentage = (value / total) * 100;
  return Number(percentage.toFixed(decimalPlaces));
};


const ProgressBar = ({ value, total, color }) => {
  const percentage = calculatePercentage(value, total);

  return (
    <div className="progress shadow-sm" style={{ height: 5 }}>
      <div className={`progress-bar bg-${color}`} role="progressbar" style={{ width: `${percentage}%` }}>
      </div>
    </div>
  );
};

const ContractStatement = () => {
  const navigate = useNavigate();
   const { showAmount, showDate} = Helper();
   const { getRequest } = HttpApi();
   const { contract_no } = useParams()
   const [statements, setStatements] = useState([]);
   const [contractdata, setContractData] = useState([]);

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        const response = await getRequest('contractdata/' + contract_no);
        setContractData(response.data.data.contractdata);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchStatements = async () => {
      try {
          const response = await getRequest('contract/statements/' + contract_no);
          setStatements(response.data.data.statements);
        } catch (error) {
          console.error(error);
      }
    };

      fetchStatements();
      fetchContractData();
  }, []);
  
    const collectedPercentage = calculatePercentage(contractdata.collected_amount, contractdata.contract_amount, 2);
    const balancePercentage = calculatePercentage(contractdata.contract_amount - contractdata.collected_amount, contractdata.contract_amount, 2);    

   return (
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
          {/* <div className="row mb-3">
              <div className="col-md-8 mx-auto">
                <div className="page-title-content">
                  <h4> Contract No: {contractdata.contract_no}</h4>
                </div>
              </div>
            </div> */}

          <div className="row">
  <div className="col-md-8 mx-auto">
    <div className="card">
      <div className="card-body">
        <h5 className="mb-3">Contract No: {contractdata.contract_no} Stastictics</h5>
        <div className="row">
          <div className="col-md-6 col-xl-6">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <h6 className="text-muted mb-0">Investment Amount</h6>
                </div>
                <div className="align-items-center">
                  <h4 className="d-flex align-items-center">{showAmount(contractdata.investment_amount)}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-6">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <h6 className="text-muted mb-0">ROI Amount</h6>
                </div>
                <div className="align-items-center">
                  <h4 className="d-flex align-items-center">{showAmount(contractdata.contract_amount)}</h4>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <h6 className="text-muted mb-0">ROI Amount</h6>
                </div>
                <div className="align-items-center">
                  <h4 className="d-flex align-items-center">
                  {showAmount(contractdata.roi_amount)}
                  </h4>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row">
      <div className="col-md-6 col-xl-6">
        <div className="card shadow-lg">
          <div className="card-body">
            <div className="mb-4">
              <span className="text-success float-right">
                {collectedPercentage}%
              </span>
              <h5 className="card-title mb-0">Collected Amount</h5>
            </div>
            <div className="align-items-center mb-4">
              <h2 className="align-items-center mb-0">
                <i className="fas fa-arrow-up text-success mr-1" /> {showAmount(contractdata.collected_amount)}
              </h2>
            </div>
            <ProgressBar value={contractdata.collected_amount} total={contractdata.contract_amount} color="success" />
          </div>
        </div>
      </div>

      <div className="col-md-6 col-xl-6">
        <div className="card shadow-lg">
          <div className="card-body">
            <div className="mb-4">
              <span className="text-danger float-right">
                {balancePercentage}%
              </span>
              <h5 className="card-title mb-0">Balance</h5>
            </div>
            <div className="align-items-center mb-4">
              <h2 className="align-items-center mb-0">
                <i className="fas fa-arrow-down text-danger mr-1" />
                {showAmount(contractdata.contract_amount - contractdata.collected_amount)}
              </h2>
            </div>
            <ProgressBar value={contractdata.contract_amount - contractdata.collected_amount} total={contractdata.contract_amount} color="danger" />
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</div>


            <div className="row">
            <div className="col-md-8 mx-auto">
                <div className='card'>
                  <div className='card-body'>
                  <h4 className="mb-4">Payment Transactions ({statements.length})</h4>
                  
                    <ul className="transactions-list px-15">
                    {statements.map((statement) => (
                      <li key={statement.id}>
                        <div className="transactions-box">
                          <div className="transactions-image">
                          <i className="bi bi-check text-white"></i>
                          </div>
                          <div className="transactions-contain">
                            <div>
                              <h5>Payemet of {showAmount(statement.investor_amount)} - {statement.contract_no}</h5>
                              <h6>{showDate(statement.statement_due_date)}</h6>
                            </div>
                            <h5>{showAmount(statement.investor_amount)}</h5>
                          </div>
                        </div>
                      </li>
                      ))}
                      </ul>
                  
                  </div>
                </div>
              </div>



              

            </div>
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default ContractStatement;
