import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import Swal from "sweetalert2";
import Helper from '../functions/Helper';
import HttpApi from '../api/HttpApi';

const WithdrawRequest = () => {
   const { showAmount, formatDate} = Helper();
   const navigate = useNavigate();
   const {postRequest, getRequest} = HttpApi();
   const [withdraws, setWithdraws] = useState([]);
   const [investor, setInvestor] = useState([]);

   useEffect(() => {
    const fetchInvestor = async () => {
      try {
        const response = await getRequest('dashboard');
        setInvestor(response.data.data.investor);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchWithdraw = async () => {
      try {
        const response = await getRequest('withdraws');
        setWithdraws(response.data.data.withdraws);
      } catch (error) {
        console.error(error);
      }
    };

    fetchInvestor();
    fetchWithdraw();
 }, []);

  const [amount, setAmount] = useState('');
  const [payment, setPayment] = useState('mtn');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFocus = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    setLoading(true);

    const amount_data = {
      amount: amount,
      payment: payment,
      phone: phone,
      name: name
    };

    try {
      const response = await postRequest("withdraw", amount_data);
      if (response.data.status === "success") {
        setErrors({});
        showNotification("success", response.data.message.success[0]);
        navigate('/withdraws');
      } else if (response.data.status === "error") {
        setErrors(response.data.message.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
};

const showNotification = (icon, title) => {
  Swal.fire({
    icon: icon,
    title: title,
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    customClass: {
      popup: "colored-toast",
    },
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

   return (
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">

          <div className="row">
              <div className="col-md-8 mx-auto">

            <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                <h4 className="mb-4">Withdraw Request Form</h4>
                  <div className="mb-4 text-center">
                    <p className="text-gray mb-1">Available Balance</p>
                    <h3 className="fw-semi-bold">{showAmount(investor?.investor_wallet)}</h3>
                  </div>
                  <div className="d-flsex justify-content-between mb-2">
                  <form onSubmit={handleWithdraw}>
                     <div className="form-group">
                        <label htmlFor="amount">Enter Withdraw Amount</label>
                        <input 
                        type="text" 
                        name="amount"
                        className={`form-control form-control-lg ${
                          errors.amount ? "is-invalid" : ""
                        }`}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        onFocus={() => handleFocus('amount')}
                        />
                        {errors.amount && (
                          <div className="invalid-feedback">{errors.amount[0]}</div>
                        )}
                     </div>

                     <div className="form-group">
                    <div className="mt-3">
                    <label htmlFor="payment">Select Payment Method</label>
                      <div className="custom-control custom-radio mb-2">
                        <input
                          type="radio"
                          id="mtn"
                          name="payment"
                          className="custom-control-input"
                          value="MTN Mobile Money"
                          checked={payment === 'mtn'}
                          onChange={() => setPayment('mtn')}
                        />
                        <label className="custom-control-label" htmlFor="mtn">
                          MTN Mobile Money
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="airtel"
                          name="payment"
                          className="custom-control-input"
                          value="Airtel Mobile Money"
                          checked={payment === 'airtel'}
                          onChange={() => setPayment('airtel')}
                        />
                        <label className="custom-control-label" htmlFor="airtel">
                          Airtel Mobile Money
                        </label>
                      </div>
                    </div>
                  </div>

                     <div className="form-group">
                        <label htmlFor="phone">Enter Mobile Number</label>
                        <input 
                        type="text" 
                        name="phone"
                        className={`form-control form-control-lg ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onFocus={() => handleFocus('phone')}
                        />
                        {errors.phone && (
                          <div className="invalid-feedback">{errors.phone[0]}</div>
                        )}
                     </div>

                     <div className="form-group">
                        <label htmlFor="name">Enter Mobile Number Names</label>
                        <input 
                        type="text" 
                        name="name"
                        className={`form-control form-control-lg ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onFocus={() => handleFocus('name')}
                        />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name[0]}</div>
                        )}
                     </div>
                     <div className="mt-2">                     
                        <button
                          type="submit"
                          className="btn btn-info btn-lg btn-block"
                          onClick={handleWithdraw}
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            "Submit Request"
                          )}
                        </button>
                     </div>                      
                  </form>
                  </div>
                  
                </div>
              </div>
            </div>

            </div>
            </div>
            </div>
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default WithdrawRequest;
