import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import HttpApi from '../api/HttpApi';
import Helper from '../functions/Helper';


const Dashboard = () => {
   const { showAmount, formatDate} = Helper();
   const { getRequest} = HttpApi();
   const [investor, setInvestor] = useState([]);
   const [investordata, setInvestorData] = useState([]);
   const [statements, setStatement] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('dashboard');
        setInvestor(response.data.data.investor);
        setInvestorData(response.data.data.investordata);
        setStatement(response.data.data.statements);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
 }, []);


   return ( 
      <DashboardLayout>
        <div className="content-body">
          <div className="container-fluid">
          {/* <div className="row mb-3">
              <div className="col-md-8 mx-auto">
                <div className="page-title-content">
                  <h4> Dashboard</h4>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-md-8 mx-auto">
              <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                <h5 className="mb-4">Wallet Overview</h5>
                  <div className="mb-4 text-center">
                    <p className="text-gray mb-1">Available Balance</p>
                    <h3 className="fw-semi-bold text-success">{showAmount(investor?.investor_wallet)}</h3>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <p className="text-gray mb-1">Total Investment Amount</p>
                      <h5 className="fw-semi-bold">{showAmount(investordata?.total_invest_amount)}</h5>
                    </div>
                    <div>
                      <p className="text-gray mb-1">Total ROI Amount</p>
                      <h5 className="fw-semi-bold">{showAmount(investordata?.total_contract_amount)}</h5>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <p className="text-gray mb-1">Total Earning Amount</p>
                      <h5 className="fw-semi-bold">{showAmount(investordata?.total_collected_amount)}</h5>
                    </div>
                    <div>
                      <p className="text-gray mb-1">Total Balance</p>
                      <h5 className="fw-semi-bold">{showAmount(investordata?.total_contract_amount - investordata?.total_collected_amount)}</h5>
                    </div>
                  </div>
                  <div className="btn-group d-flex justify-content-center mt-2">
                    <Link to="/withdraw-request">
                    <button className="btn btn-warning btn-block">
                      Make Withdraw
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-md-6">
              <div className="card card-contract">
  <div className="card-body">
    <div className="row text-center mt-4">
      <div className="col-sm-12">
        <h3 className="fw-semi-bold text-success">
        {showAmount(investor.investor_withdraw_amount)}
        </h3>
        <p className="text-muted mb-0">Total Withdrawn Amount</p>
      </div>
    </div>
    <hr />
    <h5 className="mb-0">Information Overview</h5>
    <ul className="card-contract-features">
      <li>
        Investor No: <span className="float-right">{investor.investor_no}</span>
      </li>
      <li>
        Names: <span className="float-right">{investor.investor_name}</span>
      </li>
      <li>
      Email: <span className="float-right">{investor.email && <>{investor.email}</>}</span>
     </li>
      <li>
        Telephone: <span className="float-right">{investor.investor_telephone}</span>
      </li>
      <li>
        Address: <span className="float-right">{investor.investor_address}</span>
      </li>
      <li>
        Source Income: <span className="float-right">{investor.investor_source_income}</span>
      </li>
    </ul>
  </div>
</div>

              </div>
            </div>


            <div className='row'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-body'>
                    <h4 className="mb-4">Recent Transactions</h4>
                  
                  <ul className="transactions-list px-15">
                  {statements.map((statement) => (
                    <li key={statement.id}>
                      <div className="transactions-box">
                        <div className="transactions-image">
                        <i className="bi bi-check text-white"></i>
                        </div>
                        <div className="transactions-contain">
                          <div>
                            <h5>Payemet of {showAmount(statement.investor_amount)} - {statement.contract_no}</h5>
                            <h6>{formatDate(statement.created_at)}</h6>
                          </div>
                          <h5>{showAmount(statement.investor_amount)}</h5>
                        </div>
                      </div>
                    </li>
                    ))}
                    </ul>
                    </div>
                </div>
            </div>
            </div>

              </div>
              </div>
            
          </div>
        </div>
      </DashboardLayout>
    );
};

export default Dashboard;
